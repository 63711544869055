import { useStateMachine } from 'little-state-machine'
import { useMemo } from 'react'

import { initGaEventAction, updateGaEventAction, clearGaEventAction } from './actions'

export const useGaEvent = () => {
  const { state, actions } = useStateMachine({
    clearGaEventAction,
    initGaEventAction,
    updateGaEventAction
  })

  const updateGaEvent = (gaEvent) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(gaEvent)
    // actions.updateGaEventAction(gaEvent)
  }

  const clearGaEvent = () => {
    actions.clearGaEventAction({})
  }

  const initGaEvent = () => {
    actions.initGaEventAction(undefined)
  }

  const gaEvent = useMemo(() => state.gaEvent, [state])

  return {
    clearGaEvent,
    gaEvent,
    initGaEvent,
    updateGaEvent
  }
}
